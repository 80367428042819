@font-face {
  font-family: "Gilroy";
  src: url("/assets/fonts/Gilroy-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("/assets/fonts/Gilroy-Medium.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("/assets/fonts/Gilroy-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("/assets/fonts/Gilroy-ExtraBold.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Orton Future";
  src: url("/assets/fonts/Orton-Future.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Dharma Gothic";
  src: url("/assets/fonts/Dharma-Gothic-ExBoldItalic.woff2") format("woff2");
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Pixellari";
  src: url("/assets/fonts/Pixellari.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
