@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply font-gilroy bg-blackAlt;
  scroll-behavior: smooth;
}

input[type="search"]::-webkit-search-cancel-button {
  /* Remove default input style */
  -webkit-appearance: none;
}

.bar-of-progress {
  z-index: 500;
}

@layer components {
  .gradient-separator {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 30%,
      black 55%,
      rgba(0, 0, 0, 0) 100%
    );
  }
}

@layer utilities {
  .text-glow {
    text-shadow: 0 0 2px #ffffffbb;
  }

  .text-glow-cream {
    text-shadow: 0 0 10px theme("colors.cream");
  }

  .text-glow-purple {
    text-shadow: 0 0 19px theme("colors.purple.500");
  }

  .text-glow-neon {
    text-shadow: 0px -5px 14px rgb(0 232 255 / 67%),
      0px 3px 16px rgb(193 59 255);
  }

  .text-glow-neon--blue {
    text-shadow: 0 0 10px rgba(122, 152, 244, 0.9);
  }

  .slanted {
    clip-path: polygon(0 0, 100% 5%, 100% 100%, 0 90%);
  }

  .text-vertical {
    writing-mode: vertical-rl;
    text-orientation: mixed;
  }

  .line-clamp-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .line-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .perspective-1200 {
    perspective: 1200px;
  }

  .preserve-3d {
    transform-style: preserve-3d;
  }

  .rotate-y-25 {
    transform: rotateY(25deg);
  }

  .-rotate-y-25 {
    transform: rotateY(-25deg);
  }

  .object-center-top {
    object-position: center top;
  }

  .container-sm {
    @apply max-w-[850px] mx-auto px-4 md:px-6 w-full;
  }

  .container-base {
    @apply max-w-[960px] mx-auto px-4 md:px-6 w-full;
  }

  .container-md {
    @apply max-w-[1160px] mx-auto px-4 md:px-6 w-full;
  }

  .container-lg {
    @apply max-w-[1300px] mx-auto px-4 md:px-6 w-full;
  }

  .container-xl {
    @apply max-w-[1496px] mx-auto px-4 md:px-6 w-full;
  }

  .container-3xl {
    @apply max-w-[1920px] mx-auto px-4 md:px-6 w-full;
  }

  .pb-page {
    @apply pb-14 md:pb-20;
  }

  .space-y-page {
    @apply space-y-20 md:space-y-24;
  }

  .space-y-page-lg {
    @apply space-y-24;
  }

  .clip-trapezoid {
    clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
  }

  .vertical-black-gradient {
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 75%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  .vertical-black-gradient-reverse {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 1) 50%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  .vertical-black-gradient-reverse-full {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.9) 90%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  .break-all-words {
    word-spacing: 100vw;
  }

  .hover-scale {
    @apply hover:scale-101 focus:scale-101 active:scale-98 transition-transform;
  }
}

/* Safari 15+ displays a gray border while loading.
 https://bugs.webkit.org/show_bug.cgi?id=243601
 */
@supports (font: -apple-system-body) and (-webkit-appearance: none) {
  img[loading="lazy"] {
    clip-path: inset(0.6px);
  }
}

/* Remove chrome autofill background */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: theme("colors.cream");
}

/* Disable animations and transitions for people that prefer reduced motion */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
